<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Distrik Sidigi"
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @select="select"
    @deselect="deselect"
    @blur="() => (findText = null)"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    :show-arrow="true"
    v-bind="$attrs"
  >
    <a-select-option v-for="d in dataDistrik" :key="d.id" :label="d.id + ' - ' + d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.id + ' - ' + d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
    wilayah: {
      default: () => [],
      type: Array,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value', 'select'],
  setup(props, { emit }) {
    const dataDistrik = ref([])
    const findText = ref(null)
    const { wilayah, value } = toRefs(props)
    const loading = ref(false)
    const joinOptions = toRefs(props).joinOptions

    const fetchDataDistrik = debounce((wilayah, q = null) => {
      if (wilayah === undefined || wilayah.length <= 0) wilayah = null

      loading.value = true
      apiClient
        .get('/api/filter/district-sidigi', {
          params: { wilayah, q },
        })
        .then(response => {
          let sorted = response.data
          if (joinOptions.value.length > 0) {
            const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
            sorted = values(merged)
          }
          dataDistrik.value = sorted.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        })
        .catch(err => console.log(err))
        .finally(() => {
          loading.value = false
        })
    }, 500)

    onMounted(() => {
      fetchDataDistrik(wilayah.value)
    })

    watch(wilayah, (after, before) => {
      emit('update:value', [])
      fetchDataDistrik(after)
    })

    watch(value, (after, before) => {
      if (after === null || after === undefined || after === []) fetchDataDistrik(wilayah.value)
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchDataDistrik(wilayah.value, value)
    }, 300)

    const highlight = value => {
      if (value === undefined || value === null) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const select = value => {
      if (findText.value !== null) {
        fetchDataDistrik(wilayah.value)
        findText.value = null
      }
      emit('select', value)
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchDataDistrik(wilayah.value)
        findText.value = null
      }
    }

    return {
      dataDistrik,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
