<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-region class="m-2" v-model:value="region" />
        <filter-provinsi class="m-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area class="m-2" v-model:value="area" v-model:provinsi="provinsi" v-model:region="region" />
        <filter-kabupaten-kota
          class="m-2"
          v-model:value="kabupaten"
          v-model:region="region"
          v-model:provinsi="provinsi"
          v-model:area="area"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-distributor
          class="m-2"
          v-model:value="distributor"
          v-model:region="region"
          v-model:provinsi="provinsi"
          v-model:area="area"
          v-model:kabupaten="kabupaten"
        />
        <a-select
        :options="[
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          :mode="null"
          allow-clear
          class="m-2"
          style="width: 300px;"
          placeholder="Pilih Status"
          v-model:value="status">
        </a-select>
        <ASelect
          v-has-role="[ROLE_ADMIN_BK]"
          :options="[
            { value: 1, label: 'Distributor' },
            { value: 11, label: 'Distributor Curah' },
          ]"
          :mode="null"
          allow-clear
          class="m-2"
          style="width: 300px;"
          placeholder="Pilih Tipe Distributor"
          v-model:value="type">
        </ASelect>
        <a-input-search class="m-2" v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <!-- tombol  -->
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          @click="setAktif"
          title="Aktifkan Distributor"
          v-has-access="['distributors.destroy']"
          ><i class="fa fa-check" aria-hidden="true"></i>
        </a-button>
        <a-modal
          title="Informasi"
          v-model:visible="modal4Visible"
          @ok="aktif"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist distributor yang akan di aktifkan</p>
          <p v-else>Anda yakin ingin mengaktifkan distributor?</p>
        </a-modal>

        <a-button
          type="danger"
          class="ml-2 "
          @click="setModal1Visible(true)"
          title="Nonaktifkan Distributor"
          v-has-access="['distributors.destroy']"
          ><i class="fa fa-ban" aria-hidden="true"></i>
        </a-button>
        <a-modal
          title="Informasi"
          v-model:visible="modal1Visible"
          @ok="nonaktifkan"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist distributor yang akan di non aktifkan</p>
          <p v-else>Anda yakin ingin menonaktifkan distributor?</p>
        </a-modal>
        <a-button
          type="primary"
          class="ml-2"
          v-has-access="['distributors.store']"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Tambah Distributor"
          @click="
            () => {
              formState = {}
              formState.status_approval = 0
              // formState.type = 1
              modal2Visible = true
              formState.title_state = 'Tambah Distributor'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
         <a-button
          type="primary"
          class="ml-2 "
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- tabel -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #customer="{ text }">
                  <span>
                    {{ text }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>
                <template #missing="{ text }">
                  <span
                    :class="[
                      text === 'Aktif'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-default',
                    ]"
                    >missing</span
                  >
                </template>
                <template #action="{record}">
                  <div class="d-flex align-items-center justify-content-between">
                    <a-tooltip title="Master Gudang">
                      <a
                        class="btn btn-sm"
                        @click="
                          () => {
                            router.push({
                              path: '/master/gudang-distributor',
                              query: { distributor: record.id },
                            })
                          }
                        "
                      >
                        <i class="fe fe-list" />
                      </a>
                    </a-tooltip>
                    <a-tooltip
                      :title="record.type == 1 ? `Master Toko`: `Master Data Customer Curah`">
                      <a
                        class="btn btn-sm"
                        @click="
                          () => {
                            router.push({
                              path: record.type == 1 ? '/master/data-toko' : '/atbusiness/customer-curah',
                              query: { distributor: record.id },
                            })
                          }
                        "
                      >
                        <i class="fe fe-list" />
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Edit Distributor">
                      <a
                        href="javascript: void(0);"
                        v-has-access="['distributors.update']"
                        class="btn  btn-sm"
                        @click="editDistributor(record.id)"
                      >
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Lihat Distributor">
                      <a
                        href="javascript: void(0);"
                        v-has-access="['distributors.show']"
                        class="btn  btn-sm"
                        @click="lihatDistributor(record.id)"
                      >
                        <small>
                          <i class="fe fe-eye" />
                        </small>
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <a-modal
      v-model:visible="modal2Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal2Visible = false"
    >
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>

      <m-modal ref="modalRef"></m-modal>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed } from 'vue'
import apiClient from '@/services/axios'
import MModal from './modal'
import FilterRegion from '@/components/filter/FilterRegional'
import FilterProvinsi from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'

import { useRoute, useRouter } from 'vue-router'
import { Modal, message } from 'ant-design-vue'

import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import { ROLE_ADMIN_BK } from '@/helpers'

export default {
  components: {
    MModal,
    FilterRegion,
    FilterProvinsi,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modal4Visible = ref(false)
    const modalRef = ref('')
    const formState = ref({
      status_approval: 0,
      foto_ktp: null,
      status_approval: 0,
      wilayah_id: null,
      salesman: [],
      salesmans: [],
      district_sidigi_code: null,
      type: null,
    })
    const q = ref('')
    const status = ref(null)
    const type = ref(1)
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])

    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const area = ref([])
    // data-model untuk filter
    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])

    const dataRegional = ref([])
    const dataProvince = ref([])
    const dataArea = ref([])
    const dataProduct = ref([])
    const dataBrand = ref([])
    const isPosting = ref(false)

    const errorMessage = ref(null)

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/distributors', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `distributors_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        distributor: distributor.value,
        status: status.value,
        type: type.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/distributors', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const setModal1Visible = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist distributor yang akan di non aktifkan!',
          })
          return
        }
      }
      modal1Visible.value = visible
    }

    onMounted(() => {
      fetchData()
    })

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const lihatDistributor = id => {
      apiClient
        .get(`/api/distributors/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal2Visible.value = true
          formState.value.title_state = 'Lihat Distributor'
          formState.value.readOnly = true
          apiClient
            .get(`/api/distributors/${id}/codes`)
            .then(({ data }) => {
              formState.value.vendorCodes = Object.assign([], data.items)
            })
            .catch(error => console.error(error))

          apiClient
            .get(`/api/distributors/${id}/salesman`)
            .then(({ data }) => {
              formState.value.salesman = data.map(item => item.salesman_id)
              formState.value.salesmans = data
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error({ failure: error }))
    }

    const editDistributor = id => {
      apiClient
        .get(`/api/distributors/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal2Visible.value = true
          formState.value.title_state = 'Edit Distributor'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          apiClient
            .get(`/api/distributors/${id}/codes`)
            .then(({ data }) => {
              formState.value.vendorCodes = Object.assign([], data.items)
            })
            .catch(error => console.error(error))

          apiClient
            .get(`/api/distributors/${id}/salesman`)
            .then(({ data }) => {
              formState.value.salesman = data.map(item => item.salesman_id)
              formState.value.salesmans = data
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error({ failure: error }))
    }

    const search = () => {
      fetchData()
    }

    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Ajukan Distributor',
          content: 'Apakah anda ingin melanjutkan Ajukan Distributor ?',

          async onOk() {
            isPosting.value = true

            const form_data = new FormData()
            for (var key in formState.value) {
              if (key === 'active') {
                form_data.append(key, formState.value[key] ? 1 : 0)
              } else if (key === 'salesman') {
                formState.value.salesmans.forEach((item, index) => {
                  form_data.append(`salesman[${index}][id]`, item.id)
                  form_data.append(`salesman[${index}][salesman_id]`, item.salesman_id || item.id)
                })

              } else form_data.append(key, formState.value[key])
            }
            // TODO: validasi

            await apiClient
              .post('/api/distributors', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.vendorCodes
                const promises = []
                codes.forEach(c => {
                  c.vendor_id = vendor_id
                  const post = apiClient
                    .post('/api/distributors/' + vendor_id + '/codes', c)
                    .then(r => {
                      console.log('codes', r)
                    })
                  promises.push(post)
                })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data distributor.'),
                )
                modal2Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambah')
              })
              .catch( async ({ response: { data }}) => {
                message.error('Gagal menyimpan!')

                data.forEach(item => {
                  message.error(item.message)
                })

              })
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {

        const form_data = new FormData()
        for (var key in formState.value) {
          if (key === 'active' || key === 'kelengkapan_dokumen') {
            form_data.append(key, formState.value[key] ? 1 : 0)
          } else if (key === 'salesman') {
            formState.value.salesmans.forEach((item, index) => {
              form_data.append(`salesman[${index}][id]`, item.id)
              form_data.append(`salesman[${index}][salesman_id]`, item.salesman_id || item.id)
            })
          } else {
            form_data.append(key, formState.value[key] === null || formState.value[key] === undefined ? '' : formState.value[key])
          }
        }
        // TODO: validasi

        Modal.confirm({
          title: 'Konfirmasi Edit Distributor',
          content: 'Apakah anda ingin melanjutkan Edit Distributor ?',
          onOk() {
            isPosting.value = true
            
            apiClient
              .post('/api/distributors/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.vendorCodes
                const promises = []
                codes
                  .filter(c => c.id === null)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const post = apiClient
                      .post('/api/distributors/' + vendor_id + '/codes', c)
                      .then(r => {
                        console.log('edit post codes', r)
                      })
                    promises.push(post)
                  })
                codes
                  .filter(c => c.is_deleted)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const _delete = apiClient
                      .delete('/api/distributors/' + vendor_id + '/codes/' + c.id)
                      .then(r => {
                        console.log('delete codes', r)
                      })
                    promises.push(_delete)
                  })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data distributor.'),
                )
                modal2Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil diupdate')
              })
              .catch(({ response: { data }}) => {
                message.error('Gagal mengupdate!')

                data.forEach(item => {
                  message.error(item.message)
                })
              })
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      }
    }

    const nonaktifkan = () => {
      // modal1Visible.value = false
      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        let find = data.value.find(item => item.id == k)

        promises.push(
          apiClient.post('/api/distributors/' + k + '?_method=PUT', {
            active: false,
            type: find.type,
          }),
        )
      })

      Promise.all(promises)
        .then(() => {
          console.log('completed')
          message.success('Distributor Berhasil dinonaktifkan')
        })
        .finally(err => {
          state.selectedRowKeys = []
          modal1Visible.value = false
          fetchData()
          state.loading = false
        })
    }

    // const visible = ref(false)
    const setAktif = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist distributor yang akan di aktifkan!',
          })
          return
        }
      }
      modal4Visible.value = visible
    }

    const aktif = () => {
      // modal1Visible.value = false
      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        let find = data.value.find(item => item.id == k)

        promises.push(
          apiClient.post('/api/distributors/' + k + '?_method=PUT', {
            active: true,
            type: find.type,
          }),
        )
      })

      Promise.all(promises)
        .then(() => {
          console.log('completed')
          message.success('Distributor Berhasil diaktifkan')
        })
        .finally(err => {
          state.selectedRowKeys = []
          modal4Visible.value = false
          fetchData()
          state.loading = false
        })
    }

    return {
      route,
      router,
      modal1Visible,
      modal2Visible,
      modal3Visible,
      modal4Visible,
      setModal1Visible,
      q,
      searchInput,
      data,
      columns,
      meta,
      handleTableChange,
      startRow,
      dataRegional,
      region,
      provinsi,
      kabupaten,
      dataProvince,
      dataProduct,
      dataBrand,
      dataArea,
      area,
      formState,
      lihatDistributor,
      editDistributor,
      search,
      state,
      ...toRefs(state),
      onSelectChange,
      nonaktifkan,
      submit,
      distributor,
      isPosting,
      fetchData,
      fetchXlsx,
      modalRef,
      errorMessage,
      status,
      type,
      // visible,
      setAktif,
      aktif,
      ROLE_ADMIN_BK,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
