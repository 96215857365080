const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'code',
    slots: { customRender: 'kodedistributor' },
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'name',
  },
  {
    title: 'Alamat Distributor',
    dataIndex: 'address',
  },
  {
    title: 'Total Gudang',
    dataIndex: 'jumlah_gudang',
  },
  {
    title: 'Cakupan Area Toko',
    dataIndex: 'areas',
  },
  {
    title: 'Total Kapasitas Gudang',
    dataIndex: 'kapasitas_gudang',
  },
  {
    title: 'Jumlah Toko',
    dataIndex: 'jumlah_toko',
  },
  {
    title: 'Status Distributor',
    dataIndex: 'status_text',
    slots: { customRender: 'status' },
  },
  // {
  //   title: 'Nama Produk',
  //   dataIndex: 'product_name',
  // },
  {
    title: 'Aksi',
    slots: { customRender: 'action' },
  },
]

export default acolumns
